<script setup lang="ts">
const auth = useAuth();

const layoutStore = useLayoutStore();

const isMenuOpen = ref(false);

const classObject = computed(() => ({
  ["is-bottom-up"]: layoutStore.bottomUp,
}));

const scrollable = ref<HTMLDivElement | null>(null);

useScrollOnChatUpdate(scrollable.value);
</script>

<template>
  <NuxtLayout name="default">
    <div class="dashboard" :class="classObject" ref="scrollable">
      <DashboardHeader v-model:menu="isMenuOpen">
        {{ layoutStore.title }}
      </DashboardHeader>
      <DashboardMain
        class="main"
        :class="{ 'is-menu-open': isMenuOpen }"
        :fixedHeight="layoutStore.fixedHeight"
      >
        <slot />
      </DashboardMain>
      <DashboardFooter />
      <DashboardMenu>
        <TransitionExpand>
          <Modal v-if="isMenuOpen" @close="isMenuOpen = false">
            <MenuItem
              label="Uitloggen"
              icon="logout"
              @click="
                auth.logout();
                isMenuOpen = false;
              "
            />
          </Modal>
        </TransitionExpand>
      </DashboardMenu>
    </div>
  </NuxtLayout>
</template>

<style lang="scss" scoped>
@use "@/assets/scss/abstracts" as *;

.dashboard {
  transition: $transition-layout;
  transition-property: background-color;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100vh;
  overflow: auto;
  -webkit-overflow-scrolling: touch;

  @supports (-webkit-touch-callout: none) {
    // Only apply in mobile Safari
    height: stretch;
  }

  &.is-bottom-up {
    flex-direction: column-reverse;
  }
}

// .main {
//   transition: $transition-layout;
//   transition-property: scale, filter;

//   .is-menu-open {
//     scale: 0.95;
//     filter: blur(1px);
//   }
// }
</style>
