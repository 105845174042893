<script setup lang="ts"></script>

<template>
  <footer id="footer" class="footer"></footer>
</template>

<style lang="scss" scoped>
@use "@/assets/scss/abstracts" as *;

.footer {
  position: fixed;
  z-index: 10;
  bottom: 0;
  left: 0;
  right: 0;
  padding-bottom: env(safe-area-inset-bottom);
  box-sizing: content-box;
  background-color: $color-background-content;
}
</style>
