<script setup lang="ts"></script>

<template>
  <div id="menu" class="menu">
    <slot></slot>
  </div>
</template>

<style lang="scss" scoped>
@use "@/assets/scss/abstracts" as *;

.menu {
  position: fixed;
  z-index: 20;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);
}
</style>
