<script setup lang="ts">
interface Props {
  menu: boolean;
}

const props = withDefaults(defineProps<Props>(), {});

const emit = defineEmits(["update:menu"]);

const route = useRoute();

const layoutStore = useLayoutStore();

const classObject = computed(() => ({
  [`has-background-${layoutStore.theme}`]: layoutStore.theme,
  [`has-background-wallpaper`]: layoutStore.backgroundImage,
}));
</script>

<template>
  <nav class="navigation" :class="classObject">
    <TransitionFade group tag="div" :duration="300">
      <div
        v-if="layoutStore.backgroundImage"
        :key="layoutStore.backgroundImage"
        class="wallpaper"
        :class="{ 'has-overlay': route.name === 'chat' }"
        :style="`background-image: url(${layoutStore.backgroundImage})`"
      ></div>
    </TransitionFade>
    <div class="controls" :style="`height: ${layoutStore.headerHeight}`">
      <div class="aside is-left">
        <TransitionFade group>
          <Icon
            v-if="layoutStore.back"
            name="arrow_back"
            class="button is-back"
            @click="layoutStore.goBack"
          />
          <Icon
            v-else-if="layoutStore.menu"
            class="button is-menu"
            :name="props.menu ? 'close' : 'menu'"
            @click="emit('update:menu', true)"
          />
        </TransitionFade>
      </div>
      <div class="title">
        <slot />
      </div>
      <div class="aside is-right">
        <!-- <Icon name="notifications" class="button is-notifications" /> -->
      </div>
    </div>
  </nav>
</template>

<style lang="scss" scoped>
@use "@/assets/scss/abstracts" as *;

.navigation {
  position: fixed;
  inset: 0;
  padding-top: env(safe-area-inset-top);
  flex-shrink: 0;
  box-sizing: content-box;
  color: $color-text-light;
  transition: $transition-layout;
  transition-property: height, background-color, color;

  &::after {
    content: "";
    position: absolute;
    inset: 0;
    background: linear-gradient(
      180deg,
      rgba($color-background-content, 0) 25%,
      $color-background-content 75%
    );
    pointer-events: none;
  }

  &.has-background-default {
    background-color: $color-background-default;
  }

  &.has-background-auth {
    background-color: $color-background-auth;
    color: $color-accent;
  }

  &.has-background-chat {
    background-color: $color-background-chat;
  }

  &.has-background-wallpaper {
    color: $color-text-light;
  }
}

.wallpaper {
  background-size: 100% auto;
  background-position: top center;
  background-repeat: no-repeat;
  position: fixed;
  inset: 0;
  transition: $transition-layout;
  transition-property: opacity;

  &::after {
    content: "";
    position: absolute;
    inset: 0;
    pointer-events: none;
    background: linear-gradient(
      180deg,
      rgba($color-black, 0%) 25%,
      $color-black 50%
    );
  }

  &.has-overlay {
    &::after {
      background: linear-gradient(
        180deg,
        rgba($color-black, 50%) 25%,
        $color-black 50%
      );
    }
  }
}

.controls {
  position: relative;
  height: 100%;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: $transition-layout;
  transition-property: height;
  z-index: 1;
}

.aside {
  width: 2rem;
  align-self: flex-start;
  &.is-right {
    margin-left: auto;
  }
}

.button {
  font-size: 2rem;
  align-self: flex-start;
  cursor: pointer;
}

.title {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.2rem;
  align-self: flex-end;
  flex-grow: 1;
  text-align: center;
}

.icon {
  font-size: 2rem;
}
</style>
