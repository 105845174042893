<script setup lang="ts">
interface Props {
  fixedHeight: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  fixedHeight: false,
});

const layoutStore = useLayoutStore();

const mainStyleObject = computed(() => {
  return {
    minHeight: props.fixedHeight ? 0 : "auto",
  };
});

const contentStyleObject = computed(() => {
  return {
    marginTop: `calc(${layoutStore.headerHeight} + env(safe-area-inset-top))`,
    minHeight: props.fixedHeight ? 0 : "auto",
  };
});
</script>

<template>
  <main class="main" :style="mainStyleObject">
    <div class="content" :style="contentStyleObject">
      <slot></slot>
    </div>
  </main>
</template>

<style lang="scss" scoped>
@use "@/assets/scss/abstracts" as *;

.main {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  pointer-events: none;
  flex-grow: 1;
}

.content {
  pointer-events: all;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  border-radius: 1rem 1rem 0 0;
  background-color: $color-background-content;
  transition: $transition-layout;
  transition-property: margin-top;
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
  flex: 1;
}
</style>
