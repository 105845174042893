export const useLayoutStore = defineStore("layout", () => {
  const router = useRouter();

  const route = useRoute();

  const categoriesStore = useCategoriesStore();

  /**
   * Defines the page title that is displayed in the header
   */
  const title = computed(() => {
    switch (route.name) {
      case "chat":
        // Set active category name as title on chat page
        return categoriesStore.activeCategory
          ? categoriesStore.activeCategory.title
          : route.meta.title;
      default:
        return route.meta.title;
    }
  });

  /**
   * Defines the height of the dashboard header
   */
  const headerHeight = computed(() => {
    switch (theme.value) {
      case "welcome":
        return "180px";
      case "chat":
        return "70px";
      default:
        return "100px";
    }
  });

  /**
   * Defines the background image of the dashboard
   */
  const backgroundImage = computed(() => {
    switch (route.name) {
      case "welcome":
        return new URL(`~/assets/img/welcome.jpg`, import.meta.url).href;
      case "chat":
        // Set a background image for chat if a category is set
        return categoriesStore.activeCategory &&
          categoriesStore.activeCategory.wallpaper_url
          ? categoriesStore.activeCategory.wallpaper_url
          : null;
      default:
        return null;
    }
  });

  /**
   * Defines the theme of the dashboard
   */
  const theme = computed((): string => {
    // Don't change background color when a background image should be displayed to make for better transitions
    if (route.name === "chat" && backgroundImage.value) {
      return theme.value;
    }
    return route.meta.theme ? (route.meta.theme as string) : "default";
  });

  /**
   * Defines if the page is rendered in reverse to scroll to the bottom by default (for example in a chat interface)
   */
  const bottomUp = computed(() => {
    switch (route.name) {
      // Display content bottom up to show latest chats first
      case "chat":
        return true;
      default:
        return false;
    }
  });

  /**
   * Defines if the page has a fixed height of 100%
   */
  const fixedHeight = computed(() => {
    return route.meta.fixedHeight ? true : false;
  });

  /**
   * Defines if the page has a fixed height of 100%
   */
  const menu = computed(() => {
    return route.meta.menu ? true : false;
  });

  /**
   * Defines the action for the back button in the header
   */
  const back = computed(() => {
    return route.meta.back;
  });

  /**
   * Method that will be used by the back button
   */
  function goBack() {
    if (typeof route.meta.back === "function") {
      route.meta.back();
    } else if (typeof route.meta.back === "string") {
      router.push({ name: route.meta.back });
    }
  }

  return {
    title,
    theme,
    headerHeight,
    backgroundImage,
    bottomUp,
    fixedHeight,
    menu,
    back,
    goBack,
  };
});
